<template>
  <div>
    <v-app-bar app :color="theme ? '' : 'primary'" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer" />

      <v-spacer />

      <div class="d-flex flex-row align-center gap-4">
        <IconNotification />
        <IconTheme />
        <IconAccount />
      </div>
    </v-app-bar>

    <v-navigation-drawer class="b_menu" app v-model="drawer" :width="250">
      <vue-perfect-scrollbar class="drawer--scroll" :settings="scrollSettings">
        <div class="b_menu__logo">
          <img
            :src="
              !theme
                ? require('@/assets/logo/default.svg')
                : require('@/assets/logo/white.svg')
            "
            alt="Logo Biobe"
          />
        </div>

        <v-list dense class="b_menu__list">
          <template v-for="(item, index) in menus">
            <v-list-item
              link
              class="b_menu__list__item"
              :key="index"
              :to="{ name: item.path }"
              v-if="item.children.length === 0"
            >
              <v-list-item-icon class="b_menu__list__item--icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title class="b_menu__list__item--title">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>

            <v-list-group
              no-action
              class="b_menu__list__item"
              :key="index"
              v-model="item.active"
              v-if="item.children.length > 0"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon class="b_menu__list__item--icon">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="b_menu__list__item--title">
                  {{ item.title }}
                </v-list-item-title>
              </template>

              <template v-for="(child, x) in item.children">
                <v-list-item
                  class="b_menu__list__item"
                  :to="{ name: child.path }"
                  :key="x"
                  v-if="child.children.length === 0"
                >
                  <v-list-item-title class="b_menu__list__item--title">
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-group
                  sub-group
                  no-action
                  :key="x"
                  v-if="child.children.length > 0"
                  v-model="child.active"
                >
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon class="b_menu__list__item--icon">
                        {{ child.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="b_menu__list__item--title">
                      {{ child.title }}
                    </v-list-item-title>
                  </template>

                  <template v-for="(grandchild, y) in child.children">
                    <v-list-item
                      class="b_menu__list__item"
                      :to="{ name: grandchild.path }"
                      :key="y"
                    >
                      <v-list-item-title class="b_menu__list__item--title">
                        {{ grandchild.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list-group>
              </template>
            </v-list-group>
          </template>
        </v-list>
      </vue-perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  components: {
    IconAccount: () => import('@/components/icons/account'),
    IconTheme: () => import('@/components/icons/theme'),
    IconNotification: () => import('@/components/notification/icon'),
    VuePerfectScrollbar: () => import('vue-perfect-scrollbar')
  },

  data: () => ({
    drawer: true,
    selectedItem: 0,
    scrollSettings: {
      maxScrollbarLength: 50,
      suppressScrollX: true
    }
  }),

  created() {
    if (this.$vuetify && this.$vuetify.breakpoint.mobile) {
      this.drawer = false;
    }
  },

  computed: {
    theme() {
      return this.$vuetify.theme.isDark;
    },

    menus() {
      return this.$store.getters['authentication/getMenus'];
    }
  }
};
</script>
